<template lang="pug">
  v-container(fluid)
    v-toolbar.mb-2(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span Order Statuses
    v-data-table(
      :items="orderStatusList"
      :headers="headers"
      dense
      :loading="orderStatusGetting"
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.id }}
          td {{ item.name }}
          td {{ item.created_at.dateFormat_ENUS() }}
          td {{ item.updated_at.dateFormat_ENUS() }}
</template>
<script>
import { getVars } from '@/libs/api-helper.extra'
import VueGetApi from '@/libs/classes/VueGetApi.class'
import orderStatusRepository from '@/repositories/order-status.repository'
import searchDelay from '@/libs/searchDelay.extra'

const getOrderStatusesDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Date Created', value: 'created_at' },
    { text: 'Date Updated', value: 'updated_at' },
  ],
})

export default {
  name: 'OrderStatusTable',
  created () {
    this.getOrderStatuses()
    this.websocketEvents()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('order-status'),
    }
  },
  methods: {
    getOrderStatuses () {
      if (this.orderStatusGetting) return
      const variableNames = this.getOrderStatusVariableNames()
      const requestOrderStatus = new VueGetApi(this, variableNames)
      requestOrderStatus.fetch(orderStatusRepository.index())
    },
    getOrderStatusVariableNames () {
      return {
        loading: 'orderStatusGetting',
        error: 'orderStatusGetErrors',
        list: 'orderStatusList',
      }
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.DBStoreEvent)
    },
    DBStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'OrderStatus') {
        this.orderStatusDBStoreEvent(data)
      }
    },
    orderStatusDBStoreEvent (data) {
      const list = this.orderStatusList.map(item => Object.assign({ ...item }))
      this.orderStatusList = list.objectInsertion(data, 'id', 'id')
    },
  },
}
</script>
